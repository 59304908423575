import { useRef, useState, useCallback, useEffect } from 'react';
import { get, set } from 'lodash';
export const boolSerializer = {
    deserialize: str => str === 'true',
    serialize: data => (data ? 'true' : 'false'),
};
export const objectSerializer = {
    deserialize: str => JSON.parse(str),
    serialize: data => JSON.stringify(data),
};
export function buildObjectSerializer(options) {
    return {
        deserialize: (data) => {
            var _a;
            const res = objectSerializer.deserialize(data);
            (_a = options.datePaths) === null || _a === void 0 ? void 0 : _a.forEach(path => {
                const val = get(res, path);
                if (val && typeof val === 'string') {
                    set(res, path, new Date(val));
                }
            });
            return res;
        },
        serialize: objectSerializer.serialize,
    };
}
export const stringSerializer = {
    deserialize: str => str,
    serialize: data => data,
};
export function useLocalStorageState(initial, key, serializer) {
    const getStoredValue = useCallback(() => {
        const storageValue = localStorage.getItem(key);
        if (storageValue === null)
            return null;
        refStorageValue.current = storageValue;
        try {
            const parsedValue = serializer ? serializer.deserialize(storageValue) : storageValue;
            if (parsedValue)
                return parsedValue;
        }
        catch (error) {
            console.error({
                message: `Serialization error in useLocalStorageState by key: "${key}"`,
                exception: error,
            });
        }
        return null;
    }, [key, serializer]);
    const refStorageValue = useRef(null);
    const [value, setValue] = useState(() => { var _a; return (_a = getStoredValue()) !== null && _a !== void 0 ? _a : initial; });
    useEffect(() => {
        if (refStorageValue.current === null) {
            // @ts-ignore
            refStorageValue.current = serializer ? serializer.serialize(initial) : initial;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serializer]);
    const setValueHandler = useCallback((value) => {
        setValue(value);
        try {
            const stringValue = serializer ? serializer.serialize(value) : value;
            refStorageValue.current = stringValue;
            localStorage.setItem(key, stringValue);
        }
        catch (error) {
            console.error({
                message: `Serialization error in useLocalStorageState by key: "${key}"`,
                exception: error,
            });
        }
    }, [key, serializer]);
    useEffect(() => {
        const syncValue = () => {
            const storageValue = getStoredValue();
            if (storageValue !== null)
                setValue(storageValue);
        };
        syncValue();
        window.addEventListener('storage', syncValue);
        return () => window.removeEventListener('storage', syncValue);
    }, [getStoredValue, key, serializer]);
    return [value, setValueHandler];
}

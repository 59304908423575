import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Table } from 'reactstrap';
import { FiltersValueType } from '@just-ai/analytic-front';
import { Total, UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Spinner, useDebounceFn, usePromiseProcessing, usePromisifyComponent } from '@just-ai/just-ui';
import { Serializer, useLocalStorageState } from '@just-ai/just-ui/dist/utils/useLocalStorageState';

import { t } from 'localization';
import { axios } from 'pipes/functions';
import { useCurrentUser } from 'store/slices/currentUser';

import { useAppContext } from 'components/AppContext';
import { NoAccounts } from 'components/NoAccounts';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import isAccess from 'helpers/isAccessFunction';
import { LimitsHeader } from 'views/AccountDetail/components/LimitsHeader';
import DeleteUserModal from 'views/AccountDetail/modals/DeleteUserModal';
import EditUserModal from 'views/AccountDetail/modals/EditUserModal';
import FilterPanel from 'views/Accounts/FilterPanel';

import AccountsUserRow from './AccountsUserRow';
import AccountUserFilterInterface, { FILTER_KEYS } from './AccountUserFilterInterface';

import styles from './styles.module.scss';

const accountsadminService = new AccountsadminService(axios);

const COLS = ['email.id', 'name', 'groupsAndRoles'];

const filterSerializer: Serializer<FiltersValueType[]> = {
  deserialize: str => JSON.parse(str),
  serialize: data => JSON.stringify(data),
};

interface AccountUsersProps {
  account: Total;
}
const AccountUsers = ({ account }: AccountUsersProps) => {
  const pageParams = useParams<{ accountId: string }>();
  const { appConfig } = useAppContext();
  const { user } = useCurrentUser();
  const { paginationInfo, changePageSize, changePage, changeTotalPages } = usePagination({
    totalCount: 0,
    pageNum: 0,
    pageSize: 10,
  });

  const [getLimitsByProductState, getLimitsByProductApi] = usePromiseProcessing(async (accountId: number) =>
    accountsadminService.getLimitsByProduct(accountId)
  );

  const filterInterface = useMemo(() => {
    if (!appConfig) return;
    if (!getLimitsByProductState.result && !getLimitsByProductState.error) return;
    return new AccountUserFilterInterface(
      appConfig,
      `ACCOUNT_USERS_FILTERS_${user?.userId}_${pageParams.accountId}`,
      getLimitsByProductState.result
    );
  }, [appConfig, getLimitsByProductState.error, getLimitsByProductState.result, pageParams.accountId, user?.userId]);

  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useLocalStorageState<FiltersValueType[]>(
    [],
    `ACCOUNT_USERS_FILTERS_${user?.userId}_${pageParams.accountId}`,
    filterSerializer
  );
  const [findAccountUsersState, findAccountUsersApi, optimisticFindAccountUsersApi] = usePromiseProcessing(
    async (searchText: string, filterData: FiltersValueType[], page: number, pageSize: number) => {
      if (!pageParams.accountId) return;
      const { products, workplacesProduct, workplacesFitsLimit } = getFiltersDataForRequest(filterData);

      return accountsadminService.findAccountUsers({
        accountId: parseInt(pageParams.accountId),
        page: page,
        size: pageSize,
        searchText,
        products: products,
        workplacesProduct: workplacesProduct,
        workplacesFitsLimit: workplacesFitsLimit,
      });
    },
    {
      deps: [pageParams.accountId],
    }
  );

  const debouncedFindAccountUsersApi = useDebounceFn(findAccountUsersApi, 500);

  useEffect(() => {
    if (!pageParams.accountId) return;
    if (!isAccess('ACCOUNTS_READ') && !isAccess('ROLES_READ')) return;
    getLimitsByProductApi(parseInt(pageParams.accountId));
  }, [pageParams.accountId, getLimitsByProductApi]);

  useEffect(() => {
    const pageSize = findAccountUsersState.result?.paging.pageSize;
    if (pageSize !== undefined) changePageSize(pageSize);
    const totalCount = findAccountUsersState.result?.paging.totalCount;
    if (totalCount !== undefined) changeTotalPages(totalCount);
  }, [
    changePageSize,
    changeTotalPages,
    findAccountUsersState.result?.paging?.pageSize,
    findAccountUsersState.result?.paging?.totalCount,
  ]);

  const onFilterChange = useCallback(
    (filter: FiltersValueType[], searchText: string) => {
      changePage(0);
      setFilterData(filter);
      setSearchText(searchText);
    },
    [changePage, setFilterData, setSearchText]
  );

  const getFiltersDataForRequest = useCallback((filterValues: FiltersValueType[]) => {
    const products = filterValues?.find(f => f.key === FILTER_KEYS.PRODUCT)?.options || [];
    const multipleFilters = filterValues?.find(f => f.key === FILTER_KEYS.MULTIPLE_FILTER);
    const subFiltersValues = multipleFilters?.subFiltersValues;

    const workplacesProductMultipleFilter = subFiltersValues?.find(option => option.key === FILTER_KEYS.LIMIT_PRODUCT);
    const workplacesFitsLimitMultipleFilter = subFiltersValues?.find(option => option.key === FILTER_KEYS.LIMITS);
    const workplacesProduct = workplacesProductMultipleFilter?.option;
    const workplacesFitsLimit = workplacesFitsLimitMultipleFilter?.option === 'INCLUDED';

    return {
      products,
      workplacesProduct,
      workplacesFitsLimit: workplacesFitsLimitMultipleFilter ? workplacesFitsLimit : undefined,
    };
  }, []);

  useEffect(() => {
    debouncedFindAccountUsersApi(searchText, filterData, paginationInfo.pageNum, paginationInfo.pageSize);
  }, [debouncedFindAccountUsersApi, searchText, filterData, paginationInfo.pageNum, paginationInfo.pageSize]);

  const [selectedUser, setSelectedUser] = useState<UserSearchRecord>();

  const [editUserModalNode, callEditUserModal] = usePromisifyComponent((resolve, reject, opened) => (
    <EditUserModal isOpen={opened} onSubmit={resolve} onCancel={reject} account={account} user={selectedUser} />
  ));
  const onUserEdit = useCallback(
    (user: UserSearchRecord) => {
      setSelectedUser(user);

      callEditUserModal().finally(() => {
        setSelectedUser(undefined);
        optimisticFindAccountUsersApi(searchText, filterData, paginationInfo.pageNum, paginationInfo.pageSize);
        getLimitsByProductApi(parseInt(pageParams.accountId));
      });
    },
    [
      callEditUserModal,
      optimisticFindAccountUsersApi,
      searchText,
      filterData,
      getLimitsByProductApi,
      pageParams.accountId,
      paginationInfo.pageNum,
      paginationInfo.pageSize,
    ]
  );
  const [deleteUserModalNode, callDeleteUserModal] = usePromisifyComponent((resolve, reject, opened) => (
    <DeleteUserModal isOpen={opened} account={account} onSubmit={resolve} onCancel={reject} user={selectedUser} />
  ));
  const onDeleteUser = useCallback(
    (user: UserSearchRecord) => {
      setSelectedUser(user);
      callDeleteUserModal().finally(() => {
        setSelectedUser(undefined);
        optimisticFindAccountUsersApi(searchText, filterData, paginationInfo.pageNum, paginationInfo.pageSize);
        getLimitsByProductApi(parseInt(pageParams.accountId));
      });
    },
    [
      callDeleteUserModal,
      optimisticFindAccountUsersApi,
      searchText,
      filterData,
      getLimitsByProductApi,
      pageParams.accountId,
      paginationInfo.pageNum,
      paginationInfo.pageSize,
    ]
  );

  return (
    <div className={styles.AccountUsers}>
      {(findAccountUsersState.loading || getLimitsByProductState.loading) && <Spinner />}
      {getLimitsByProductState.result && Object.keys(getLimitsByProductState.result.workplacesLimits).length > 0 && (
        <LimitsHeader limitsData={getLimitsByProductState.result} />
      )}
      {filterInterface && (
        <FilterPanel
          searchText={searchText}
          filterData={filterData as FiltersValueType[]}
          filterInterface={filterInterface}
          placeholder={t('AccountUsers:Search:Placeholder')}
          onFilterChange={onFilterChange}
          pageName='accountUsers'
        />
      )}

      {findAccountUsersState.result?.records?.length ? (
        <>
          <Table data-test-id='usersPage.userListTable' className={styles.table}>
            <thead>
              <tr>
                {COLS.map(col => (
                  <th key={`user_list_${col}`}>{t(`AccountUsers:Table:Header:${col}`)}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody className={styles.Users__list}>
              {findAccountUsersState.result.records.map(record => (
                <AccountsUserRow
                  key={`user-item-${record.id}`}
                  onEdit={onUserEdit}
                  onDelete={onDeleteUser}
                  user={record}
                />
              ))}
            </tbody>
          </Table>
          <div className={styles.AccountUsers__paginationContainer}>
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              totalCount={paginationInfo.totalCount}
              changePage={changePage}
              changePageSize={changePageSize}
            />
          </div>
        </>
      ) : !findAccountUsersState.loading ? (
        <NoAccounts />
      ) : null}
      {editUserModalNode}
      {deleteUserModalNode}
    </div>
  );
};

AccountUsers.displayName = 'AccountUsers';

export default React.memo(AccountUsers);

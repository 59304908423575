import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { FilterModal } from '@just-ai/just-ui';
import classNames from 'classnames';
import './FiltersPanel.scss';
import DateRangeModal from './FilterModal/DateRangeModal';
import RadioModal from './FilterModal/RadioModal';
import CheckboxModal from './FilterModal/CheckboxModal';
import NumericRangeModal from './FilterModal/NumericRangeModal';
import CustomFieldsModal from './FilterModal/CustomFieldsModal';
import TextModal from './FilterModal/TextModal';
import TreeModal from './FilterModal/TreeModal';
import Label from './FilterLabel/Label';
import { buildCheckboxLabel, buildDateRangeLabel } from '../../utils/buildLabelFunctions';
import { CompositeModal } from './FilterModal/CompositeModal';
import MultipleFilter from './FilterModal/MultipleFilter';
export const FILTER_MODAL_COMPONENTS = {
    ['DATE_TIME_RANGE']: (props) => _jsx(DateRangeModal, Object.assign({}, props)),
    ['RADIO']: (props) => _jsx(RadioModal, Object.assign({}, props)),
    ['NUMERIC_RANGE']: (props) => _jsx(NumericRangeModal, Object.assign({}, props)),
    ['CHECKBOX']: (props) => _jsx(CheckboxModal, Object.assign({}, props)),
    ['CUSTOM_FIELDS_IN_RESPONSE']: (props) => (_jsx(CustomFieldsModal, Object.assign({}, props))),
    ['TEXT']: (props) => _jsx(TextModal, Object.assign({}, props)),
    ['TREE']: (props) => _jsx(TreeModal, Object.assign({}, props)),
    ['COMPOSITE_FILTER']: (props) => _jsx(CompositeModal, Object.assign({}, props)),
    ['MULTIPLE_FILTER']: (props) => _jsx(MultipleFilter, Object.assign({}, props)),
};
const FILTER_MODAL_LABEL = {
    ['DATE_TIME_RANGE']: buildDateRangeLabel,
    ['CHECKBOX']: buildCheckboxLabel,
};
const Filter = ({ usedFiltersKeys, onDelete, filter, reactKey, onChoose, filters, hidden, onChange, locale, optionsArguments, filtersFetcher, t, timeZone, }) => {
    const [showModal, setShowModal] = useState(false);
    const onDeleteFilter = useCallback(() => {
        onDelete(filter === null || filter === void 0 ? void 0 : filter.key);
    }, [filter, onDelete]);
    const options = (filters === null || filters === void 0 ? void 0 : filters.map(x => ({ label: x.label, value: x.key, isDisabled: usedFiltersKeys === null || usedFiltersKeys === void 0 ? void 0 : usedFiltersKeys.includes(x.key) }))) || [];
    const onCancel = () => {
        setShowModal(false);
    };
    const onConfirm = (value, clear) => {
        var _a;
        if (value || filters || usedFiltersKeys) {
            const currentFilter = filters === null || filters === void 0 ? void 0 : filters.find(x => x.key === (filter === null || filter === void 0 ? void 0 : filter.key));
            const currentFilterRestrictions = (_a = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.settings) === null || _a === void 0 ? void 0 : _a.removeFiltersByKeys;
            if (currentFilterRestrictions === null || currentFilterRestrictions === void 0 ? void 0 : currentFilterRestrictions.length) {
                currentFilterRestrictions.forEach(key => {
                    onDelete(key);
                });
            }
            setShowModal(false);
            onChange(Object.assign({ key: filter.key, type: filter === null || filter === void 0 ? void 0 : filter.type }, value), clear);
        }
    };
    const label = useMemo(() => {
        var _a, _b;
        const withName = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.nameWithValue;
        if (filter && withName) {
            return FILTER_MODAL_LABEL[filter.type](filter.label, (_b = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _b === void 0 ? void 0 : _b.find(x => x.key === filter.key), t, locale, timeZone);
        }
        else if (filter) {
            return (_jsx("p", { style: { fontWeight: 400, marginBottom: 0 }, children: _jsx(Label, { filter: filter, optionsArguments: optionsArguments, filtersFetcher: filtersFetcher, t: t, locale: locale }) }));
        }
        return '';
    }, [filter, optionsArguments, t, locale, timeZone, filtersFetcher]);
    // @ts-ignore
    return (_jsx("div", { className: classNames('filtersPanel_filtersContainer', `filtersPanel_filtersContainer_type_${filter === null || filter === void 0 ? void 0 : filter.type}`, filter && 'withFilter', hidden && 'hidden'), children: _jsx(FilterModal, { label: label, id: filter === null || filter === void 0 ? void 0 : filter.key, type: filter ? 'fulfilled' : 'new', maxHeight: 1000, options: options, onOpen: filter ? () => setShowModal(true) : undefined, onClose: filter ? () => setShowModal(false) : undefined, forceShowModal: filter ? showModal : undefined, onSelect: value => onChoose(reactKey, value), onRemove: filter && onDeleteFilter, required: Boolean(filter === null || filter === void 0 ? void 0 : filter.isRequired), stayClosed: filter === null || filter === void 0 ? void 0 : filter.stayClosed, placeholder: t('Analytics: filter placeholder'), children: filter &&
                FILTER_MODAL_COMPONENTS[filter.type] &&
                FILTER_MODAL_COMPONENTS[filter.type]({
                    filter,
                    onCancel,
                    onConfirm,
                    onDelete: onDeleteFilter,
                    locale,
                    optionsArguments,
                    showModal,
                    filtersFetcher,
                    timeZone,
                    t,
                }) }) }));
};
export default React.memo(Filter);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { DatePicker, JustSelect } from '@just-ai/just-ui';
import './DateRangeModalStatic.scss';
import './BaseModalFilter.scss';
import { buildEndBorder, buildStartBorder } from '../../../utils/buildDefaultValueFunctions';
import { BUILD_DEFAULT_VALUE_FUNC } from '../FiltersPanel';
import classNames from 'classnames';
import moment from 'moment';
const RangeType = {
    Between: 'Between',
    Any: 'Any',
    After: 'After',
    Before: 'Before',
};
class DateRangeModalState {
    constructor() {
        this.rangeType = RangeType.Between;
        this.counter = 0;
    }
}
export default class DateRangeModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b, _c, _d, _e;
        super(props);
        this.value = null;
        this.setRangeType = (values) => __awaiter(this, void 0, void 0, function* () {
            const value = values[0];
            const { rangeType: currentRangeType, counter } = this.state;
            console.log(value, currentRangeType);
            if (currentRangeType === value)
                return;
            const newRangeType = value;
            let result = null;
            if (newRangeType === RangeType.Any) {
                result = null;
            }
            const endTimeBorder = buildEndBorder();
            const startTimeBorder = buildStartBorder();
            const { Before, After, Between, Any } = RangeType;
            let dateFrom = this.value ? (this.value.from ? new Date(this.value.from) : new Date()) : null;
            let dateTo = this.value ? (this.value.to ? new Date(this.value.to) : new Date()) : null;
            if (newRangeType === Before) {
                if (currentRangeType === Between && dateTo) {
                    result = { from: new Date(dateTo), to: new Date(dateTo) };
                }
                else if (currentRangeType === Any) {
                    result = { from: new Date(endTimeBorder), to: new Date(endTimeBorder) };
                }
                else if (currentRangeType === After && dateFrom) {
                    result = { from: new Date(dateFrom), to: new Date(dateFrom) };
                }
            }
            if (newRangeType === After) {
                if (currentRangeType === Between && dateFrom) {
                    result = { from: dateFrom, to: buildEndBorder(new Date(dateFrom)) };
                }
                else if (currentRangeType === Any) {
                    result = { from: new Date(startTimeBorder), to: new Date(startTimeBorder) };
                }
                else if (currentRangeType === Before && dateTo) {
                    result = { from: new Date(dateTo), to: new Date(dateTo) };
                }
            }
            if (newRangeType === Between && currentRangeType === Any) {
                result = { from: new Date(startTimeBorder), to: new Date(endTimeBorder) };
            }
            if (newRangeType === Between && (currentRangeType === Before || currentRangeType === After) && dateFrom && dateTo) {
                result = { from: buildStartBorder(dateFrom), to: buildEndBorder(dateTo) };
            }
            this.value = result;
            this.setState({ rangeType: value, counter: counter + 1 });
        });
        this.preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        this.onChange = (value) => {
            if (value instanceof Date) {
                this.value = { from: new Date(value), to: new Date(value) };
            }
            else if (value) {
                this.value = { from: value === null || value === void 0 ? void 0 : value.startDate, to: value === null || value === void 0 ? void 0 : value.endDate };
            }
            else if (value === null) {
                this.value = null;
            }
            if (this.state.rangeType === RangeType.Any && value !== null) {
                this.setState({ rangeType: RangeType.Between });
            }
            else {
                this.forceUpdate();
            }
            if (this.props.showModal === false) {
                this.onConfirm();
            }
        };
        this.onConfirm = () => {
            const { rangeType } = this.state;
            let value = {};
            if (this.value === null || rangeType === RangeType.Any) {
                value = {};
            }
            else if (rangeType === RangeType.Between) {
                const { from, to } = this.value;
                value = { from, to };
            }
            else if (rangeType === RangeType.Before) {
                value = { to: this.value.to };
            }
            else if (rangeType === RangeType.After) {
                value = { from: this.value.from };
            }
            value = {
                from: value.from ? this.applyTimeZone(value.from, true) : value.from,
                to: value.to ? this.applyTimeZone(value.to, true) : value.to,
            };
            this.props.onConfirm(value, false);
        };
        this.onCancel = () => {
            //setTimeout is required to set onCancel event after onChange event
            // @ts-ignore
            this.onCancelTimeout = setTimeout(() => {
                const oldValue = this.getOldValue();
                if (!oldValue && !this.props.filter.isRequired)
                    return this.props.onDelete();
                let rangeType = RangeType.Between;
                if (oldValue) {
                    const { from, to } = oldValue;
                    this.value = { to: new Date(to), from: new Date(from) };
                    if (from && !to) {
                        rangeType = RangeType.After;
                        this.value = { to: new Date(from), from: new Date(from) };
                    }
                    else if (to && !from) {
                        rangeType = RangeType.Before;
                        this.value = { to: new Date(to), from: new Date(to) };
                    }
                    else if (!to && !from) {
                        rangeType = RangeType.Any;
                        this.value = null;
                    }
                }
                this.setState({ rangeType, counter: this.state.counter + 1 });
                this.props.onCancel();
            }, 40);
        };
        this.getDefaultValue = () => {
            var _a;
            const { rangeType } = this.state;
            let from = null;
            let to = null;
            if (this.value instanceof Date) {
                from = new Date(this.value);
                to = new Date(this.value);
            }
            else if ((_a = this.value) === null || _a === void 0 ? void 0 : _a.to) {
                from = this.value.from ? new Date(this.value.from) : new Date();
                to = new Date(this.value.to);
            }
            if (rangeType === RangeType.Between) {
                return from && to ? { startDate: from, endDate: to } : undefined;
            }
            else {
                return from ? { startDate: from, endDate: from } : undefined;
            }
        };
        this.onClear = () => {
            this.value = null;
            this.setState({ counter: this.state.counter + 1 });
        };
        this.showClearButton = () => {
            return this.value !== null;
        };
        this.getMaxDate = () => {
            const date = this.applyTimeZone(new Date());
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            date.setMilliseconds(999);
            return date;
        };
        this.renderInputs = () => {
            var _a;
            const { locale, filter, t } = this.props;
            const { rangeType, counter } = this.state;
            const options = Object.keys(RangeType).map(x => {
                return { value: x, label: t(x) };
            });
            const singleDateMode = [RangeType.Between, RangeType.Any].includes(rangeType) === false;
            const maxDate = this.getMaxDate();
            return (_jsxs("div", { onKeyDown: this.preventDefault, onClick: this.preventDefault, className: classNames('BaseModalFilter_container_inputsContainer', singleDateMode && 'rdrDateInputAndPickerSingleDateMode'), children: [!((_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.disableComparison) && (_jsx(JustSelect, { value: rangeType, options: options, size: 'md', onChange: this.setRangeType }, counter)), _jsx(DatePicker, { onChange: this.onChange, defaultValue: this.getDefaultValue(), locale: locale.slice(0, 2), integratedPicker: true, maxDate: maxDate, yearsRange: [1970, new Date().getFullYear()], singleDateMode: singleDateMode, baseDate: this.baseDate }, `${counter}_${rangeType === RangeType.Between}`)] }));
        };
        const { optionsArguments, filter } = props;
        const savedOldValue = (_a = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _a === void 0 ? void 0 : _a.find(x => x.key === filter.key);
        const defaultValue = (_c = (_b = props.filter) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.defaultValue;
        const { from, to } = ((_d = props.filter) === null || _d === void 0 ? void 0 : _d.oldValue) || savedOldValue || {};
        this.state = { rangeType: RangeType.Between, counter: 0 };
        this.baseDate = this.applyTimeZone(new Date());
        if (from || to) {
            this.value = { from: new Date(from || to), to: new Date(to || from) };
            if (from && !to)
                this.state.rangeType = RangeType.After;
            if (!from && to)
                this.state.rangeType = RangeType.Before;
            this.value.to = this.applyTimeZone(this.value.to);
        }
        else if (defaultValue) {
            this.value = BUILD_DEFAULT_VALUE_FUNC['DATE_TIME_RANGE'](props.filter);
            this.value.to = this.getMaxDate();
        }
        if (!from && !to) {
            this.state.rangeType = RangeType.Any;
        }
        if ((_e = this.value) === null || _e === void 0 ? void 0 : _e.from) {
            this.value.from = this.applyTimeZone(this.value.from);
        }
        if (this.value && this.value.to > this.getMaxDate())
            this.value.to = this.getMaxDate();
    }
    componentWillUnmount() {
        if (this.onCancelTimeout) {
            clearTimeout(this.onCancelTimeout);
        }
    }
    applyTimeZone(date, reduce) {
        const { timeZone } = this.props;
        if (!timeZone)
            return date;
        const utcOffset = -moment().tz(timeZone).utcOffset();
        return moment(date)
            .add((date.getTimezoneOffset() - utcOffset) * (reduce ? -1 : 1), 'minutes')
            .toDate();
    }
}

export const accountsDetailLocalization = {
  'AccountDetail:Tabs:Users': {
    eng: 'Users',
    ru: 'Пользователи',
  },
  'AccountDetail:Back': {
    eng: 'Back',
    ru: 'Назад',
  },
  'AccountDetail:AccountId': {
    eng: 'Account ID: {id}',
    ru: 'ID аккаунта: {id}',
  },
  'AccountDetail:Invite': {
    eng: 'Invite user',
    ru: 'Пригласить пользователя',
  },
  'AccountDetail:Add': {
    eng: 'Add user',
    ru: 'Добавить пользователя',
  },
  'AccountDetail:Tabs:Invitations': {
    eng: 'Invitations',
    ru: 'Приглашения',
  },
  'AccountDetail:Tabs:InviteToProduct': {
    eng: 'Invite to product',
    ru: 'Пригласить в продукт',
  },
  'AccountUsers:Search:Placeholder': {
    eng: 'Enter user email, ID or name',
    ru: 'Введите email, ID или имя пользователя',
  },
  'AccountUsers:Table:Header:email.id': {
    eng: 'Email / ID',
    ru: 'Email / ID',
  },
  'AccountUsers:Table:Header:name': {
    eng: 'User name',
    ru: 'Имя пользователя',
  },
  'AccountUsers:Table:Header:invitation': {
    eng: 'Invitation',
    ru: 'Приглашение',
  },
  'AccountUsers:Table:Header:groupsAndRoles': {
    eng: 'Groups and roles',
    ru: 'Группы и роли',
  },
  'AccountsUserRow:Edit': {
    eng: 'Edit',
    ru: 'Редактировать',
  },
  'AccountsUserRow:Owner': {
    eng: 'Owner',
    ru: 'Владелец',
  },
  'AccountsUserRow:Delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'RenameAccountModal:Title': {
    eng: 'Rename account',
    ru: 'Переименовать аккаунт',
  },
  'RenameAccountModal:Submit': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'RenameAccountModal:Form:Name:Label': {
    eng: 'Name',
    ru: 'Название',
  },
  'RenameAccountModal:Error:required.field': {
    eng: 'Required field',
    ru: 'Обязательное поле',
    pt: 'Campo obrigatório',
  },
  'InvtitationRow:Delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'InviteUserModal:Banner:Link': {
    eng: 'Learn more',
    ru: 'Подробнее',
  },
  'InviteUserModal:Field:Roles': {
    eng: 'Groups and roles',
    ru: 'Группы и роли',
  },
  'EditUserModal:Title': {
    eng: 'Edit user',
    ru: 'Редактировать пользователя',
  },
  'DeleteUserModal:Title': {
    eng: 'Remove user',
    ru: 'Удалить пользователя',
  },
  'DeleteUserModal:Submit': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'DeleteUserModal:Description': {
    eng: 'Are you sure you want to remove user <b>{email}</b> from the account?',
    ru: 'Вы уверены, что хотите удалить пользователя <b>{email}</b> из аккаунта?',
  },
  'DeleteInviteModal:Title': {
    eng: 'Delete invitation',
    ru: 'Удалить приглашение',
  },
  'DeleteInviteModal:Description': {
    eng: 'Are you sure you want to remove the invitation for user <b>{email}</b>?',
    ru: 'Вы уверены, что хотите удалить приглашение для пользователя <b>{email}</b>?',
  },
  'EditInviteModal:Title': {
    eng: 'Edit invitation',
    ru: 'Редактировать приглашение',
  },
  'EditInviteModal:Field:Roles': {
    eng: 'Groups and roles',
    ru: 'Группы и роли',
  },
  'EditInviteModal.submit': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'EditUserModal.submit': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'InviteUserModal:BE-error:accountsadmin.captcha.missing.header': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação do CAPTCHA',
  },
  'InviteUserModal:BE-error:accountsadmin.user.by.login.not.found': {
    eng: 'User not found',
    ru: 'Пользователь не найден',
  },
  'InvtitationRow:Expires': {
    eng: 'Expires {timeLeft}',
    ru: 'Истекает {timeLeft}',
  },
  'InvtitationRow:Expires:Expired': {
    eng: 'Expired',
    ru: 'Истекло',
  },
  'AccountUsers:Filter:Product': {
    eng: 'Product',
    ru: 'Продукт',
  },
  'AccountUsers:Filter:Limits': {
    eng: 'Limits',
    ru: 'Лимиты',
  },
  'AccountUsers:Filter:Product:copilot': {
    eng: '#{config.copilot.productName}',
    ru: '#{config.copilot.productName}',
  },
  'AccountUsers:Filter:Limits:Included': {
    eng: 'Can use (included in limit)',
    ru: 'Могут пользоваться (попадают в лимит)',
  },
  'AccountUsers:Filter:Limits:NotIncluded': {
    eng: 'Cannot use (not included in limit)',
    ru: 'Не могут пользоваться (не попадают в лимит)',
  },
  'AccountUsers:LimitsHeader:Title': {
    eng: 'Workplaces',
    ru: 'Рабочие места',
  },
  'AccountUsers:Limits:Used': {
    eng: 'Used: {used}',
    ru: 'Использовано: {used}',
  },
  'AccountUsers:Limits:Max': {
    eng: 'Max: {max}',
    ru: 'Максимум: {max}',
  },
  'AccountDetail:BadgeGroups:Error:LimitTooltip': {
    eng: 'User cannot use the product because they have exceeded the limit of workplaces',
    ru: 'Пользователь не может использовать продукт, так как вышел за лимит рабочих мест',
  },
  'AccountUsers:Filter:Workplaces': {
    eng: 'Workplaces',
    ru: 'Рабочие места',
  },
  'EditUserModal:Error:accountsadmin.user.access.forbidden': {
    eng: 'Access forbidden',
    ru: 'Доступ запрещен',
  },
};

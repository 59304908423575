import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { FiltersPanel, FiltersValueType, Locale } from '@just-ai/analytic-front';
import { Button, IconButtonProps, InputGroup, InputText, useDebounce, useToggle } from '@just-ai/just-ui';

import localize, { t } from 'localization';
import { getUserLanguage } from 'pipes/functions';

import AccountUserFilterInterface from 'views/AccountDetail/tabs/AccountUsers/AccountUserFilterInterface';
import UsersFiltersFetchInterface from 'views/Users/UsersFiltersFetchInterface';

import { accountsfilterLocalization } from '../localization/accountsfilter.loc';

import { filterPanelLocalization } from './localization/filterPanel.loc';
import AccountsFiltersFetchInterface from './AccountsFiltersFetchInterface';

import styles from './styles.module.scss';

localize.addTranslations(accountsfilterLocalization);
localize.addTranslations(filterPanelLocalization);

const searchIcon: IconButtonProps[] = [{ color: 'secondary', name: 'farSearch' }];

export function transformFiltersForApi(filters: FiltersValueType[]) {
  const newFilterData: any = {};
  newFilterData.partner = null;
  filters.forEach((filter: any) => {
    if (filter.type === 'DATE_TIME_RANGE' && filter.key === 'creationDate') {
      if (filter.to) newFilterData.accountCreatedTo = new Date(filter.to);
      if (filter.from) newFilterData.accountCreatedFrom = new Date(filter.from);
    } else if (filter.type === 'RADIO') {
      if (filter.option && filter.key === 'partner') {
        newFilterData.partner = {
          ...newFilterData.partner,
          type: filter.option,
        };
      }
      if (filter.option && filter.key === 'partnerState') {
        newFilterData.partner = {
          ...newFilterData.partner,
          partnerState: filter.option,
        };
      }
    } else {
      newFilterData[filter.key] = filter.options;
    }
  });
  return newFilterData;
}

interface FilterPanelProps {
  searchText: string;
  filterData: FiltersValueType[];
  filterInterface: AccountsFiltersFetchInterface | UsersFiltersFetchInterface | AccountUserFilterInterface;
  placeholder: string;
  pageName: string;
  onFilterChange: (data: FiltersValueType[], searchText: string) => void;
}
const FilterPanel = ({
  searchText,
  filterData,
  onFilterChange,
  filterInterface,
  placeholder,
  pageName,
}: FilterPanelProps) => {
  const [filtersOpened, setToggleTrue, , toggleFilters] = useToggle();
  const [innerSearchText, setInnerSearchText] = useState(searchText);
  const searchTextInner = useDebounce(innerSearchText, 500);
  const [innerFilter, setInnerFilter] = useState<FiltersValueType[]>([]);

  useEffect(() => {
    if (searchText === searchTextInner && isEqual(filterData, innerFilter)) return;
    onFilterChange(innerFilter, searchTextInner);
  }, [filterData, innerFilter, onFilterChange, searchText, searchTextInner]);

  useEffect(() => {
    if (filterData.length > 0) {
      setToggleTrue();
    }
  }, [filterData]);

  const lang = getUserLanguage();
  const btnRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className={styles.FilterPanel}>
      <div data-test-id={`${pageName}.search`} className={styles.FilterPanel__header}>
        <InputGroup
          AppendInner={searchIcon}
          className={styles.FilterPanel__search}
          data-test-id={`${pageName}.searchPanel`}
        >
          <InputText value={innerSearchText} onChange={setInnerSearchText} placeholder={placeholder} />
        </InputGroup>
        <div>
          <Button
            innerRef={btnRef}
            color='secondary'
            flat
            onClick={toggleFilters}
            className={styles.filterBtn}
            data-test-id={`${pageName}.search.filters`}
          >
            {filtersOpened ? t('FilterPanel:Filters:HideFilters') : t('FilterPanel:Filters:ShowFilters')}
          </Button>
        </div>
      </div>
      <FiltersPanel
        filtersFetchInterface={filterInterface}
        accountId={0}
        onChange={setInnerFilter}
        values={filterData}
        locale={lang as Locale}
        resource='accounts'
        rootClassName={cn(styles.FilterPanel__filtersContainer, {
          [styles.hidden]: !filtersOpened,
        })}
        t={t}
      />
    </div>
  );
};

FilterPanel.displayName = 'FilterPanel';

export default React.memo(FilterPanel);

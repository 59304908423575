import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Button, Icon } from '@just-ai/just-ui';
import './BaseModalFilter.scss';
import { Popohover } from '../Popohover';
export default class BaseModalFilter extends Component {
    constructor() {
        super(...arguments);
        this.width = null;
        this.filtersChanged = false;
        this.withoutCancel = false;
        this.containerRef = React.createRef();
        this.checkPosition = () => {
            var _a, _b, _c, _d, _e;
            const elem = this.containerRef.current;
            if (!this.containerRef.current)
                return;
            const container = (_d = (_c = (_b = (_a = elem === null || elem === void 0 ? void 0 : elem.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.parentElement;
            const parent = elem === null || elem === void 0 ? void 0 : elem.parentElement;
            if (elem && container && parent) {
                const windowHeight = window.innerHeight;
                const { right: containerRight } = container.getBoundingClientRect();
                const { width, bottom, top } = elem.getBoundingClientRect();
                const { left } = ((_e = parent === null || parent === void 0 ? void 0 : parent.parentElement) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect()) || { left: 0 };
                if (left + width > containerRight) {
                    parent.classList.add('BaseModalFilter_container_wrapper_right');
                }
                else {
                    parent.classList.remove('BaseModalFilter_container_wrapper_right');
                }
                if (bottom > windowHeight) {
                    const maxHeight = windowHeight - top - 30;
                    elem.style.maxHeight = `${maxHeight}px`;
                    elem.style.overflowY = 'auto';
                }
                else {
                    elem.style.maxHeight = 'none';
                    elem.style.overflowY = 'visible';
                }
            }
        };
        this.updateValue = (prevProps) => { };
        this.fetchAndSaveOptions = () => { };
        this.cleareFiltersFromCurrent = (filters, key) => {
            if (!filters)
                return [];
            return filters.filter(x => x.key !== key);
        };
        this.renderInputs = () => {
            return _jsx("div", {});
        };
        this.onConfirm = () => { };
        this.onCancel = () => { };
        this.onClear = () => { };
        this.getOldValue = () => {
            var _a, _b;
            const { filter, optionsArguments } = this.props;
            if ((optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters.length) === 1 && (optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters[0].key) === 'MULTIPLE_FILTER') {
                return (_a = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters[0].subFiltersValues) === null || _a === void 0 ? void 0 : _a.find((x) => x.key === filter.key);
            }
            return ((_b = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _b === void 0 ? void 0 : _b.find(x => x.key === filter.key)) || filter.oldValue;
        };
        this.showClearButton = () => {
            return false;
        };
        this.hideButtons = () => {
            return false;
        };
        this.renderTooltip = () => {
            const { filter, t } = this.props;
            const { helpMessage, helpLink, helpLinkButtonLabel } = (filter === null || filter === void 0 ? void 0 : filter.settings) || {};
            if (!helpMessage)
                return null;
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'BaseModalFilter_container_helpLink', id: `filterModalTooltipIcon${filter.key}`, children: _jsx(Icon, { name: 'farQuestionCircle', color: 'primary' }) }), _jsxs(Popohover, { target: `filterModalTooltipIcon${filter.key}`, closable: false, children: [_jsx("p", { children: helpMessage }), helpLink && (_jsx("a", { href: helpLink, target: '_blank', rel: 'noreferrer', children: _jsx(Button, { color: 'primary', children: helpLinkButtonLabel || t('Analytics: Additional Information') }) }))] })] }));
        };
        this.renderButtons = () => {
            const { t } = this.props;
            if (this.hideButtons())
                return null;
            return (_jsxs(_Fragment, { children: [this.showClearButton() && (_jsx(Button, { flat: true, color: 'secondary', className: 'clear-filter-numeric-custom', onClick: this.onClear, size: 'sm', "data-test-id": 'FiltersPanel.FilterModal.ClearButton', children: t('NumericRangeModal:clear') })), _jsxs("div", { className: 'BaseModalFilter_container_buttonContainer', children: [!this.withoutCancel && (_jsx(Button, { onClick: this.onCancel, color: 'secondary', outline: true, size: 'sm', "data-test-id": 'FiltersPanel.FilterModal.CancelButton', children: t('Analytics: Cancel') })), _jsx(Button, { onClick: this.onConfirm, color: 'primary', size: 'sm', "data-test-id": 'FiltersPanel.FilterModal.ConfirmButton', children: t('Analytics: Confirm') })] })] }));
        };
    }
    componentDidUpdate(prevProps) {
        var _a, _b;
        const { showModal, optionsArguments, filter } = this.props;
        const { key } = filter;
        //TODO it seems to check is anything changed in filters
        if (prevProps.showModal && showModal === false) {
            this.onConfirm();
        }
        this.updateValue(prevProps);
        if (showModal && prevProps.showModal === false) {
            this.checkPosition();
            window.addEventListener('resize', this.checkPosition);
        }
        else {
            window.removeEventListener('resize', this.checkPosition);
        }
        if (!((_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.filterDependent))
            return;
        const oldFiltersJSON = JSON.stringify(this.cleareFiltersFromCurrent((_b = prevProps.optionsArguments) === null || _b === void 0 ? void 0 : _b.filters, key));
        const newFiltersJSON = JSON.stringify(this.cleareFiltersFromCurrent(optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters, key));
        if (!this.filtersChanged && oldFiltersJSON !== newFiltersJSON) {
            this.filtersChanged = true;
        }
        if (showModal && prevProps.showModal === false && this.filtersChanged) {
            this.fetchAndSaveOptions();
            this.filtersChanged = false;
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.checkPosition);
    }
    render() {
        const { filter } = this.props;
        const { label } = filter;
        const isMultipleFilter = filter.type === 'MULTIPLE_FILTER';
        return (_jsxs("div", { className: `BaseModalFilter_container ${isMultipleFilter ? 'BaseModalFilter_container_multiple' : ''}`, ref: this.containerRef, style: this.width ? { width: this.width } : {}, "data-test-id": `FiltersPanel.FilterModal.Modal_${filter.key}`, children: [_jsx("label", { style: { fontWeight: 700, color: 'var(--gray-800)' }, children: label }), this.renderTooltip(), this.renderInputs(), this.renderButtons()] }));
    }
}
